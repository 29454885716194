import React from 'react';
import PropTypes from 'prop-types';

const FormInput = ({
  className,
  color,
  id,
  name,
  type,
  placeholder,
  required,
  whiteText,
  onChange,
  disabled,
  value,
}) => {
  return (
    <label htmlFor={id} className={`${className}`}>
      <input
        className={`${
          whiteText ? `text-white placeholder-yellow-400` : ''
        } rounded w-full text-inp-lg p-inp my-input ${
          disabled
            ? 'text-gray-300 bg-gray-600'
            : `bg-${color}-800 focus:bg-${color}-900`
        }`}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
    </label>
  );
};

FormInput.propTypes = {
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  whiteText: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.text,
};

FormInput.defaultProps = {
  color: 'white',
  required: false,
  whiteText: false,
  disabled: false,
  value: '',
  onChange: () => {},
};

export default FormInput;

import React from 'react';
import PropTypes from 'prop-types';

const TitleSection = ({ color, titleClassName, title }) => {
  return (
    <section className={titleClassName}>
      <div className={`w-full h-full bg-${color}-800 opacity-50 absolute`} />
      <div className="relative pTops flex justify-center ">
        <h1 className="text-center pt-10 text-white ext-3xl tracking-wide md:text-4xl">
          {title}
        </h1>
      </div>
    </section>
  );
};
TitleSection.propTypes = {
  color: PropTypes.string,
  title: PropTypes.node,
  titleClassName: PropTypes.string,
};

export default TitleSection;

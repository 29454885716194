import React from 'react';
import PropTypes from 'prop-types';

const SubTitleSection = ({ color, children, subTitle }) => {
  return (
    <section>
      <div className="relative block-fix flex justify-center">      
            <div className="font-bold text-left text-white">
              {subTitle}            
      </div></div>
    </section>
  );
};
SubTitleSection.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  subTitle: PropTypes.string,
};
export default SubTitleSection;

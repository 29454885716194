import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import axios from 'axios';
import SubTitleSection from './subtitleSection';
import TitleSection from './titleSection';
import FormInput from './formInput';
import FormTextarea from './formTextarea';

const getEmptyContact = (color = '') => ({
  contact_name: '',
  contact_email: '',
  contact_phone: '',
  contact_message: '',
  contact_city: '',
  contact_state: '',
  color,
});

const ContactPageLayout = ({
  color,
  children,
  title,
  titleClassName,
  subTitle,
  subtitleContent,
  bgClassOption,
}) => {
  const [formState, setFormState] = useState('PRISTINE');
  const [values, setValues] = useState(getEmptyContact(color));
  const [contactResponse, setContactResponse] = useState({});

  const data = useStaticQuery(graphql`
    query InnerPageLayoutTwoQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const onChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const onSubmit = event => {
    event.preventDefault();
    setFormState('SENDING');
    setContactResponse({});
    console.log(data.site.siteMetadata.siteUrl);
    axios({
      method: 'post',
      url: `${data.site.siteMetadata.siteUrl}/backend/`,
      data: values,
      headers: {
        'Content-Type': 'json',
      },
    })
      .then(response => {
        console.log(response);
        if (response.data.status !== 200) {
          setContactResponse({
            msg:
              'There was an error trying to send the email, please try again.',
            type: 'error',
          });
          setFormState('ERROR');
        } else {
          setContactResponse({
            msg: 'Thanks for contacting us.',
            type: 'success',
          });
          setValues(getEmptyContact());
          setFormState('SUCCESS');
        }
      })
      .catch(error => {
        console.error(error);
        setContactResponse({
          msg: 'There was an error trying to send the email, please try again.',
          type: 'error',
        });
        setFormState('ERROR');
      });
  };

  return (
    <div
      className={`w-screen h-screen pr-8 relative flex justify-top flex-col`}
    >
      <TitleSection
        color={color}
        title={title}
        titleClassName={titleClassName}
        bgClassOption={bgClassOption}
      />

      <div className={bgClassOption}></div>

      <SubTitleSection color={color} subTitle={subTitle}>
        {subtitleContent}
      </SubTitleSection>

      <section className="flex flex-col">{children}</section>
      <footer className={`relative`}>
        <section className="container mx-auto flex flex-wrap justify-center md:justify-start">
          <div className="flex-grow w-full flex flex-col justify-center">
            <form
              className="flex flex-wrap justify-center"
              action="#"
              onSubmit={onSubmit}
            >
              <FormInput
                className="md:p-2 w-full"
                color={color}
                type="text"
                name="name"
                id="name"
                placeholder="What Do We Call You When We Call"
                required
                whiteText
                value={values.contact_name}
                disabled={formState === 'SENDING'}
                onChange={event => {
                  event.preventDefault();
                  onChange('contact_name', event.target.value);
                  setFormState('DIRTY');
                }}
              />
              <FormInput
                className="w-full md:p-1 md:w-1/2 md:flex"
                color={color}
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                required
                whiteText
                value={values.contact_email}
                disabled={formState === 'SENDING'}
                onChange={event => {
                  event.preventDefault();
                  onChange('contact_email', event.target.value);
                  setFormState('DIRTY');
                }}
              />
              <FormInput
                className="w-full md:p-1  md:w-1/2 md:flex"
                color={color}
                type="phone"
                name="phone"
                id="phone"
                placeholder="What Number Do We Call"
                required
                whiteText
                value={values.contact_phone}
                disabled={formState === 'SENDING'}
                onChange={event => {
                  event.preventDefault();
                  onChange('contact_phone', event.target.value);
                  setFormState('DIRTY');
                }}
              />
              <FormInput
                className="w-full md:p-1 md:w-1/2 md:flex"
                color={color}
                type="text"
                name="city"
                id="city"
                placeholder="City"
                whiteText
                value={values.contact_city}
                disabled={formState === 'SENDING'}
                onChange={event => {
                  event.preventDefault();
                  onChange('contact_city', event.target.value);
                  setFormState('DIRTY');
                }}
              />
              <FormInput
                className="w-full md:p-1  md:w-1/2 md:flex"
                color={color}
                type="text"
                name="state"
                id="state"
                placeholder="State"
                whiteText
                value={values.contact_state}
                disabled={formState === 'SENDING'}
                onChange={event => {
                  event.preventDefault();
                  onChange('contact_state', event.target.value);
                  setFormState('DIRTY');
                }}
              />

              <FormTextarea
                className="w-full md:p-1 w-full"
                color={color}
                name="message"
                id="message"
                placeholder="What Are You Looking For Us To Help You Do?"
                required
                whiteText
                value={values.contact_message}
                disabled={formState === 'SENDING'}
                onChange={event => {
                  event.preventDefault();
                  onChange('contact_message', event.target.value);
                  setFormState('DIRTY');
                }}
              />

              <input
                className={`uppercase text-white border border-white rounded tracking-widest bg-${color}-500 w-full p-inp md:w-auto md:px-24 focus:bg-${color}-800 hover:bg-${color}-800 hover:text-white focus:text-white ${
                  formState === 'SENDING'
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer'
                }`}
                type="submit"
                value="Send Message"
                disabled={formState === 'SENDING'}
              />
            </form>

            {contactResponse && contactResponse.msg && (
              <div
                className={`text-center mt-4 ${
                  contactResponse.type === 'error'
                    ? 'text-red-500'
                    : 'text-green-500'
                }`}
              >
                {contactResponse.msg}
              </div>
            )}
          </div>
        </section>
      </footer>
    </div>
  );
};
ContactPageLayout.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  subTitle: PropTypes.string,
  subtitleContent: PropTypes.node,
  bgClassOption: PropTypes.string,
};
ContactPageLayout.defaultProps = {
  subTitle: null,
};
export default ContactPageLayout;
